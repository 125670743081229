import React from 'react'

const NotFound = () => (
  <div>
    <h1>
      404 &nbsp;
      <small>Not Found :(</small>
    </h1>
  </div>
)

export default NotFound
